/**
 * @flow
 * @relayHash a3cc2b456281406e02b6cc729873fa24
 */

/* eslint-disable */

"use strict";

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RefreshInput = {|
  token?: ?string,
  clientMutationId?: ?string,
|};
export type authRefreshMutationVariables = {|
  input: RefreshInput
|};
export type authRefreshMutationResponse = {|
  +refreshToken: ?{|
    +token: string,
    +payload: any,
  |}
|};
export type authRefreshMutation = {|
  variables: authRefreshMutationVariables,
  response: authRefreshMutationResponse,
|};
*/

/*
mutation authRefreshMutation(
  $input: RefreshInput!
) {
  refreshToken(input: $input) {
    token
    payload
  }
}
*/

const node /*: ConcreteRequest*/ = (function () {
  var v0 = [
      {
        kind: "LocalArgument",
        name: "input",
        type: "RefreshInput!",
        defaultValue: null,
      },
    ],
    v1 = [
      {
        kind: "LinkedField",
        alias: null,
        name: "refreshToken",
        storageKey: null,
        args: [
          {
            kind: "Variable",
            name: "input",
            variableName: "input",
          },
        ],
        concreteType: "RefreshPayload",
        plural: false,
        selections: [
          {
            kind: "ScalarField",
            alias: null,
            name: "token",
            args: null,
            storageKey: null,
          },
          {
            kind: "ScalarField",
            alias: null,
            name: "payload",
            args: null,
            storageKey: null,
          },
        ],
      },
    ];
  return {
    kind: "Request",
    fragment: {
      kind: "Fragment",
      name: "authRefreshMutation",
      type: "Mutation",
      metadata: null,
      argumentDefinitions: (v0 /*: any*/),
      selections: (v1 /*: any*/),
    },
    operation: {
      kind: "Operation",
      name: "authRefreshMutation",
      argumentDefinitions: (v0 /*: any*/),
      selections: (v1 /*: any*/),
    },
    params: {
      operationKind: "mutation",
      name: "authRefreshMutation",
      id: null,
      text:
        "mutation authRefreshMutation(\n  $input: RefreshInput!\n) {\n  refreshToken(input: $input) {\n    token\n    payload\n  }\n}\n",
      metadata: {},
    },
  };
})();
// prettier-ignore
(node/*: any*/).hash = '9a56791410e89a625739f4939f70ac5b';

module.exports = node;
