const PROD = {
  // BASE_URL: "http://apibeta.itus.com"
  BASE_URL: "https://api.ituscapital.com",
};

const BETA = {
  // BASE_URL: 'https://apibeta.pathfinder.com'
  BASE_URL: "https://api-staging.ituscapital.com",
};

const DEV = {
  // BASE_URL: "https://api.ituscapital.com"
  BASE_URL: "http://localhost:8000",
};

const MAP = {
  production: PROD,
  beta: BETA,
  development: DEV,
};

const Config =
  process.env.REACT_APP_ENV === "beta"
    ? MAP["beta"]
    : MAP[process.env.NODE_ENV];

export default Config;
