// DON'T EDIT. THIS FILE IS GENERATED BY ./manage.py frontend_export
// CHANGE MADES MANUALLY TO THIS FILE WILL BE LOST
// mynamespace/contribs/management/commands/frontend_export.py

const CHOICES = {
  "AccountKind": {
    "CHOICES": {
      "1": "Individual",
      "2": "Corporate"
    },
    "CHOICE_LIST": [
      {
        "label": "Individual",
        "value": 1
      },
      {
        "label": "Corporate",
        "value": 2
      }
    ],
    "CORPORATE": 2,
    "INDIVIDUAL": 1
  },
  "AccountingType": {
    "CHOICES": {
      "1": "First In First Out",
      "2": "Last In First Out"
    },
    "CHOICE_LIST": [
      {
        "label": "First In First Out",
        "value": 1
      },
      {
        "label": "Last In First Out",
        "value": 2
      }
    ],
    "FIFO": 1,
    "LIFO": 2
  },
  "AssetKind": {
    "CASH": 2,
    "CHOICES": {
      "1": "Equity",
      "2": "Cash & Equivalent",
      "3": "Fixed Income",
      "4": "Other Assets"
    },
    "CHOICE_LIST": [
      {
        "label": "Equity",
        "value": 1
      },
      {
        "label": "Cash & Equivalent",
        "value": 2
      },
      {
        "label": "Fixed Income",
        "value": 3
      },
      {
        "label": "Other Assets",
        "value": 4
      }
    ],
    "EQUITY": 1,
    "INCOME": 3,
    "OTHER": 4
  },
  "FinanceKind": {
    "CASH": 2,
    "CHOICES": {
      "1": "Stock",
      "2": "Cash",
      "3": "Stock & Cash"
    },
    "CHOICE_LIST": [
      {
        "label": "Stock",
        "value": 1
      },
      {
        "label": "Cash",
        "value": 2
      },
      {
        "label": "Stock & Cash",
        "value": 3
      }
    ],
    "STOCK": 1,
    "STOCK_CASH": 3
  },
  "Frequency": {
    "ANNUAL": 4,
    "CHOICES": {
      "1": "Monthly",
      "2": "Quarterly",
      "3": "Half Yearly",
      "4": "Annual"
    },
    "CHOICE_LIST": [
      {
        "label": "Monthly",
        "value": 1
      },
      {
        "label": "Quarterly",
        "value": 2
      },
      {
        "label": "Half Yearly",
        "value": 3
      },
      {
        "label": "Annual",
        "value": 4
      }
    ],
    "HALFYEARLY": 3,
    "MONTHLY": 1,
    "QUARTERLY": 2
  },
  "MarketCap": {
    "CHOICES": {
      "1": "Large",
      "2": "Mid",
      "3": "Small"
    },
    "CHOICE_LIST": [
      {
        "label": "Large",
        "value": 1
      },
      {
        "label": "Mid",
        "value": 2
      },
      {
        "label": "Small",
        "value": 3
      }
    ],
    "LARGE": 1,
    "MID": 2,
    "SMALL": 3
  },
  "Nationality": {
    "CHOICES": {
      "1": "Resident",
      "2": "NRI"
    },
    "CHOICE_LIST": [
      {
        "label": "Resident",
        "value": 1
      },
      {
        "label": "NRI",
        "value": 2
      }
    ],
    "NRI": 2,
    "RESIDENT": 1
  },
  "PortfolioKind": {
    "CHOICES": {
      "1": "Equity",
      "2": "Discretionary"
    },
    "CHOICE_LIST": [
      {
        "label": "Equity",
        "value": 1
      },
      {
        "label": "Discretionary",
        "value": 2
      }
    ],
    "DISCRETIONARY": 2,
    "EQUITY": 1
  },
  "ProspectStatus": {
    "CHOICES": {
      "1": "Open",
      "2": "Won",
      "3": "Lost"
    },
    "CHOICE_LIST": [
      {
        "label": "Open",
        "value": 1
      },
      {
        "label": "Won",
        "value": 2
      },
      {
        "label": "Lost",
        "value": 3
      }
    ],
    "LOST": 3,
    "OPEN": 1,
    "WON": 2
  },
  "Quarter": {
    "CHOICES": {
      "1": "Q1 (Apr - Jun)",
      "2": "Q2 (Jul - Sep)",
      "3": "Q3 (Oct - Dec)",
      "4": "Q4 (Jan - Mar)"
    },
    "CHOICE_LIST": [
      {
        "label": "Q1 (Apr - Jun)",
        "value": 1
      },
      {
        "label": "Q2 (Jul - Sep)",
        "value": 2
      },
      {
        "label": "Q3 (Oct - Dec)",
        "value": 3
      },
      {
        "label": "Q4 (Jan - Mar)",
        "value": 4
      }
    ],
    "Q1": 1,
    "Q2": 2,
    "Q3": 3,
    "Q4": 4
  },
  "SecurityTransactionAssetType": {
    "CHOICES": {
      "1": "Shares",
      "2": "Mutual Funds",
      "3": "Other Assets"
    },
    "CHOICE_LIST": [
      {
        "label": "Shares",
        "value": 1
      },
      {
        "label": "Mutual Funds",
        "value": 2
      },
      {
        "label": "Other Assets",
        "value": 3
      }
    ],
    "MUTUAL_FUNDS": 2,
    "OTHER_ASSETS": 3,
    "SHARES": 1
  },
  "TaxSlab": {
    "CHOICES": {
      "1": "Senior Citizen",
      "2": "Individual"
    },
    "CHOICE_LIST": [
      {
        "label": "Senior Citizen",
        "value": 1
      },
      {
        "label": "Individual",
        "value": 2
      }
    ],
    "INDIVIDUAL": 2,
    "SENIOR_CITIZEN": 1
  },
  "TaxationUploadKind": {
    "CAPITAL_GAIN_LOSS": 1,
    "CHOICES": {
      "1": "Capital Gain/Loss Statement",
      "2": "Interests Statement",
      "3": "Dividends Statement"
    },
    "CHOICE_LIST": [
      {
        "label": "Capital Gain/Loss Statement",
        "value": 1
      },
      {
        "label": "Interests Statement",
        "value": 2
      },
      {
        "label": "Dividends Statement",
        "value": 3
      }
    ],
    "DIVIDEND_STATEMENT": 3,
    "INTEREST_STATEMENT": 2
  },
  "TransactionKind": {
    "CHOICES": {
      "1": "Inflow",
      "2": "Outflow"
    },
    "CHOICE_LIST": [
      {
        "label": "Inflow",
        "value": 1
      },
      {
        "label": "Outflow",
        "value": 2
      }
    ],
    "INFLOW": 1,
    "OUTFLOW": 2
  },
  "UploadKind": {
    "CHOICES": {
      "1": "Daily Reports Zip",
      "2": "Kotak Timeline Tracking",
      "3": "Management Fee",
      "4": "Performance Fee",
      "5": "Custody Fee",
      "6": "Market Cap",
      "7": "Taxation Report",
      "8": "Entitlement Report",
      "9": "Internal Stock Allocation",
      "10": "Kotak Stock Allocation"
    },
    "CHOICE_LIST": [
      {
        "label": "Daily Reports Zip",
        "value": 1
      },
      {
        "label": "Kotak Timeline Tracking",
        "value": 2
      },
      {
        "label": "Management Fee",
        "value": 3
      },
      {
        "label": "Performance Fee",
        "value": 4
      },
      {
        "label": "Custody Fee",
        "value": 5
      },
      {
        "label": "Market Cap",
        "value": 6
      },
      {
        "label": "Taxation Report",
        "value": 7
      },
      {
        "label": "Entitlement Report",
        "value": 8
      },
      {
        "label": "Internal Stock Allocation",
        "value": 9
      },
      {
        "label": "Kotak Stock Allocation",
        "value": 10
      }
    ],
    "CUSTODY_FEE": 5,
    "DAILY_REPORTS_ZIP": 1,
    "ENTITLEMENT": 8,
    "INTERNAL_STOCK_ALLOCATION": 9,
    "KOTAK_STOCK_ALLOCATION": 10,
    "KOTAK_TIMELINE_TRACKING": 2,
    "MANAGEMENT_FEE": 3,
    "MARKET_CAP": 6,
    "PERFORMANCE_FEE": 4,
    "TAXATION": 7
  },
  "UploadStatus": {
    "CHOICES": {
      "1": "Uploading",
      "2": "Uploaded",
      "3": "Processing",
      "4": "Processed",
      "5": "Error"
    },
    "CHOICE_LIST": [
      {
        "label": "Uploading",
        "value": 1
      },
      {
        "label": "Uploaded",
        "value": 2
      },
      {
        "label": "Processing",
        "value": 3
      },
      {
        "label": "Processed",
        "value": 4
      },
      {
        "label": "Error",
        "value": 5
      }
    ],
    "ERROR": 5,
    "PROCESSED": 4,
    "PROCESSING": 3,
    "UPLOADED": 2,
    "UPLOADING": 1
  },
  "UserKind": {
    "ADMIN": 2,
    "CHOICES": {
      "1": "Super Admin",
      "2": "Admin",
      "3": "Client",
      "4": "Distributor"
    },
    "CHOICE_LIST": [
      {
        "label": "Super Admin",
        "value": 1
      },
      {
        "label": "Admin",
        "value": 2
      },
      {
        "label": "Client",
        "value": 3
      },
      {
        "label": "Distributor",
        "value": 4
      }
    ],
    "CLIENT": 3,
    "DISTRIBUTOR": 4,
    "SUPER_ADMIN": 1
  }
};

export default CHOICES;
